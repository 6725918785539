// @ts-nocheck
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { generateDonation } from "../api/donate";
import DonationForm from "../components/DonationForm";
import SuccessPopup from "../components/FosterSuccess/FosterSuccess";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const PupsFund = () => {
  const [email, setEmail] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [customAmount, setCustomAmount] = useState<number | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showDonationBox, setShowDonationBox] = useState(true);
  const [donationId, setDonationId] = useState<number | null>(null);
  const [showPaymentBox, setShowPaymentBox] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleAmountClick = (amount: number) => {
    setAmount(amount);
    handleDonateClick(amount);
  };

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAmount(Number(e.target.value));
  };

  const handleCustomAmountSubmit = () => {
    if (customAmount && !isNaN(customAmount)) {
      setAmount(customAmount);
      handleDonateClick(customAmount);
    } else {
      setErrorMessage("Please enter a valid amount.");
    }
  };

  const handleDonateClick = async (donationAmount: number) => {
    // Make sure email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setErrorMessage("Please enter a valid email.");
      return;
    }
    if (!donationAmount || isNaN(donationAmount)) {
      setErrorMessage("Please enter a valid amount.");
      return;
    }

    setIsProcessing(true);
    try {
      const { donation_id, client_secret } = await generateDonation({
        amount: donationAmount,
        user_email: email || "", // Pass email if provided, otherwise pass an empty string
        type: "pups_fund",
      });

      setClientSecret(client_secret);
      setDonationId(donation_id);
      setShowDonationBox(false);
      setShowPaymentBox(true);
    } catch (error) {
      console.error("Error generating donation:", error);
      setErrorMessage("Error generating donation. Please try again.");
      setIsProcessing(false);
    }
  };

  return (
    <div className="mb-32 font-poppins text-gray-dark">
      <section className="bg-gray-light py-12 md:w-full">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-center text-3xl font-bold text-blue">
            Donate To The Pups Fund
          </h1>
          <h2 className="mx-6">
            The Pups Fund lets us help a dog in immediate need. Not sure which
            pup you'd like to support? Leave it to us. We'll make sure your
            donation makes a big impact.
          </h2>
        </div>
      </section>
      {showDonationBox && (
        <section className="mt-12 flex flex-col items-center">
          <div className="mb-4 flex flex-col items-center">
            <label className="mb-2 text-xl font-semibold text-blue">
              Email
            </label>
            <input
              className="mb-4 border border-2 px-2 py-1"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter Email"
            />
          </div>
          <h3 className="mb-4 text-xl font-semibold text-blue">
            Select an Amount
          </h3>
          <div className="mb-4 flex w-[300px] flex-wrap justify-center md:w-full">
            {[10, 20, 50, 100].map((amount) => (
              <button
                key={amount}
                className="mx-2 mt-2 rounded bg-blue px-4 py-2 font-bold text-white hover:bg-blue-dark"
                onClick={() => handleAmountClick(amount)}
                disabled={isProcessing}
              >
                ${amount}
              </button>
            ))}
            <button
              className="mx-2 mt-2 rounded bg-blue px-4 py-2 font-bold text-white hover:bg-blue-dark"
              onClick={() => setCustomAmount(0)}
              disabled={isProcessing}
            >
              Other
            </button>
          </div>
          {customAmount !== null && (
            <div className="flex flex-col items-center gap-2 sm:flex-row sm:gap-0">
              <label className="mr-2">Enter Donation amount:</label>
              <input
                className="border border-2 px-2 py-1"
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                min="1"
              />
              <button
                className="ml-2 rounded bg-blue px-4 py-2 font-semibold text-white hover:bg-blue-dark"
                onClick={handleCustomAmountSubmit}
                disabled={isProcessing}
              >
                Proceed
              </button>
            </div>
          )}
          {errorMessage && <p className="mt-2 text-red-500">{errorMessage}</p>}
        </section>
      )}
      {showPaymentBox && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <DonationForm
            clientSecret={clientSecret}
            donationId={donationId}
            setShowPaymentBox={setShowPaymentBox}
            setShowThankYouMessage={setShowThankYouMessage}
            setPaymentStatus={setPaymentStatus}
          />
        </Elements>
      )}
      {showThankYouMessage && (
        <div className="mt-12 flex flex-col items-center">
          <h2 className="text-2xl font-bold text-green-500">
            Payment Success!
          </h2>
        </div>
      )}
      {showThankYouMessage && (
        <SuccessPopup
          message="Thank you for your donation to Pup Profile! A confirmation will be sent to the email provided."
          onClose={() => setShowThankYouMessage(false)}
        />
      )}
    </div>
  );
};

export default PupsFund;
