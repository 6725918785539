import { buttonData } from "../types/button";
import { useEffect } from "react";
import { useGlobal } from "../Context/GlobalContext";
import { useNavigate } from "react-router-dom";

// This is the /donate page
// "Browse Dogs" button is the only button with an onClick
const Donate: React.FC = () => {
  const { params, setParams, browseChange, setBrowseChange } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBrowseDogsClick = () => {
    setParams({ ...params, adoptable_status: ["at_risk"] }); // Set global param
    setBrowseChange(!browseChange); // Trigger browseChange
    navigate("/browse"); // Navigate to BrowseDogs page
  };

  const sections = [
    {
      title: "Want to support a specific dog? Donate via their profile!",
      content: [
        {
          text: "Browse Dogs",
          link: "/browse",
          onClick: handleBrowseDogsClick,
        },
      ],
    },
    {
      title:
        "Not sure what dog to support? A donation to The Pups Fund will support a shelter dog in immediate need.",
      content: [
        {
          text: "Donate to The Pups Fund",
          link: "/pups-fund/donate",
        },
      ],
    },
    {
      title: "Support dogs in foster care by purchasing supplies!",
      content: [
        {
          text: "Wishlists",
          link: "/browse/wishlists",
        },
      ],
    },
    {
      title: "Donations can be made to Pup Profile Operations.",
      content: [
        {
          text: "Help us keep our lights on!",
          link: "/pup-profile/donate",
        },
      ],
    },
  ];

  const ButtonSection: React.FC<{ title: string; content: buttonData[] }> = ({
    title,
    content,
  }) => (
    <div className="mb-12 flex flex-col gap-5">
      <h2 className="text-center font-bold md:text-left">{title}</h2>
      <div className="flex flex-col items-center gap-6 md:flex-row md:items-start">
        {content.map((item, index) => (
          <a
            key={index}
            href={item.link}
            onClick={(e) => {
              if (item.onClick) {
                e.preventDefault();
                item.onClick();
              }
            }}
          >
            <button className="w-fit text-nowrap rounded-md bg-blue px-12 py-2 font-semibold text-white shadow-md hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-opacity-50">
              {item.text}
            </button>
          </a>
        ))}
      </div>
    </div>
  );
  return (
    <section className="font-poppins text-gray-dark md:mb-20">
      <section className="w-full bg-gray-light py-12">
        <div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Donate</h1>
          <h2 className="px-10 text-center md:px-0">
            Rescues can't do what they do without you! Whether you want to
            support a specific dog's rescue, or any dog in need, your donation
            will go straight to the care of the pups that need it most. Pup
            Profile is a 501(c)(3) nonprofit organization, so your donation is
            tax-deductible.
          </h2>
        </div>
      </section>
      <section className="mx-auto mt-10 flex max-w-5xl flex-col items-center justify-center gap-10 px-10 md:mt-20 md:flex-row md:px-0">
        <img
          src="https://pup-profile-prod.s3.us-west-2.amazonaws.com/donate-page/donate_dog.png"
          alt="dog"
          className="w-[579px]"
        />
        <div className="flex h-full flex-col justify-center">
          {sections.map((section, index) => (
            <ButtonSection key={index} {...section} />
          ))}
        </div>
      </section>
    </section>
  );
};

export default Donate;
