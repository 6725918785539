import React, { useState, useEffect } from "react";
import { retrieveWishlistDogs } from "../api/wishlistDogs";
import { publicDogModel } from "../types/publicDogModel";

export const GeneralWishlist: React.FC = () => {
  const [wishlistDogs, setWishlistDogs] = useState<publicDogModel[]>([]);

  useEffect(() => {
    const fetchWishlistDogs = async () => {
      try {
        const response = await retrieveWishlistDogs();
        setWishlistDogs(response);
      } catch (error) {
        console.error("Failed to fetch wishlist dogs:", error);
      }
    };

    fetchWishlistDogs();
  }, []);

  return (
    <section className="mb-32 font-poppins text-gray-dark">
      <section className="w-full bg-gray-light py-12">
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <h1 className="text-3xl font-bold text-blue">Wishlists</h1>
          <h2 className="px-10 text-center lg:text-left xl:px-0">
            Rescues need food and supplies for the pups they save from the
            shelter. Purchasing Wishlist items is the most direct way to support
            rescues, fosters, and the dogs in their care.
          </h2>
        </div>
      </section>

      <section className="mx-0 mt-8 grid grid-cols-1 gap-x-4 gap-y-4 px-10 sm:grid-cols-2 md:mx-14 md:px-0">
        {wishlistDogs.map((dog, index) => (
          <div
            key={dog.dog_id}
            className="mb-8 flex w-full flex-row items-center justify-center rounded bg-white"
          >
            {/* Image Container */}
            <div className="h-36 w-36 overflow-hidden rounded md:h-56 md:w-56 lg:h-64 lg:w-64">
              <img
                src={dog.images[0]?.image || "/images/default-dog.jpg"}
                alt={dog.name}
                className="h-full w-full object-cover"
              />
            </div>

            {/* Content Container */}
            <div className="ml-6 flex w-1/2 flex-col">
              <h3 className="mb-4 text-base font-semibold md:mb-6 md:text-xl">
                {dog.name}
              </h3>
              {dog.amazon_wishlist_url && (
                <a
                  href={dog.amazon_wishlist_url}
                  className="mb-2 w-36 rounded bg-blue px-4 py-2 text-center text-xs font-semibold text-white md:w-48 md:text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Amazon Wishlist
                </a>
              )}
              {dog.chewy_wishlist_url && (
                <a
                  href={dog.chewy_wishlist_url}
                  className="w-36 rounded bg-blue px-4 py-2 text-center text-xs font-semibold text-white md:w-48 md:text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chewy Wishlist
                </a>
              )}
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default GeneralWishlist;
